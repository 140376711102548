import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import "./constants/routes"
import * as ROUTES from './constants/routes';

import NoMatch from './pages/NoMatch';
import LandingPage2 from './pages/2_LandingPage';
import BlogPost from './pages/BlogPost';
// import PaymentStatus from './components/Payments/PaymentStatus';


function App()
{
    return (
        <div className="">
            <BrowserRouter>
                <HelmetProvider>
                <Routes>
                    <Route path={ROUTES.LANDINGPAGE} element={<LandingPage2 />} />
                    <Route path="/blog/:slug" element={<BlogPost />} />
                  

                    <Route path="*" element={<NoMatch />} />


                </Routes>
                </HelmetProvider>
            </BrowserRouter>

        </div>
    );
}

export default App;
