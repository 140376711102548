


const fetchAndReture = async (payLoad, url) =>
{
    if (process.env.NODE_ENV === "development")
    {
        console.log(JSON.stringify(payLoad));
    }
    
    let customer = {}
    await fetch(
        url,
        {
            method: "POST",
            // credentials: "include",
            headers: {
                "Content-Type": "Application/JSON",
                "Access-Control-Request-Method": "POST",
            },
            body: JSON.stringify(payLoad),
            maxAge: 3600,
            //   "mode": "no-cors",
        }
    )
        .then(response => response.json())
        .then(body =>
        {
            // console.log(body);
            customer = body
            
        })
        .catch(err => console.error(err));

        return customer
}


export { fetchAndReture }