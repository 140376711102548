import React from 'react'
import { Link } from 'react-router-dom'

function NoMatch() {
  return (
      <div className="min-h-screen flex items-center justify-center bg-white">
        
          <div className="max-w-md text-center p-4">
              
              <h1 className="text-4xl text-gray-800 font-bold">404 - Not Found</h1>
              <p className="text-lg text-gray-600 mt-2">Oops! The page you're looking for doesn't exist.</p>
              <Link to="/" className="mt-4 block text-primaryC hover:underline hover:primaryHoverC">
                  Go back to the homepage
              </Link>
          </div>
      </div>
  )
}

export default NoMatch