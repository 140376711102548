export const LANDINGPAGE = '/';
export const INSTALL = '/giggoinstall';

export const SIGNIN = '/signin';
export const SIGNUP = '/signup';
export const FORGOTPASSWORD = '/forgotpassword';
export const RESETPASSWORD = '/passwordreset';
export const EMAILVERIFY = '/emailverify';
export const REDIRECTACTION = '/redirectaction';

export const TASKBROWSE = '/giggo';
export const CREATEJOB = '/createtask';
export const TASK = '/task';
export const TASKERS = '/taskers';

export const PAYMENT_MAKE = '/payment/payment';
export const PAYMENT_PAYOUT = '/payment/payouts';
export const PAYMENT_SUCCESS = '/payment/setupsuccess';
export const PAYMENT_PAYOUT_REDIRECT = '/payment/payoutredirect';
export const PAYMENT_STATUS = '/paymentstatus'

export const USER_PERSONALINFO = '/user/personal-info';
export const USER_NOTIFICATION = '/user/notifications';
export const USER_PROFILE = '/user/profile';
export const USER_DASHBOARD = '/user/dashboard';

export const USER_MYTASKS = '/user/mytasks';
export const USER_MYTASK_EDIT = '/user/mytaskedit';
export const USER_MYTASK = '/user/mytask';

export const USER_MYTASKSACTIVE = '/user/mytasksactive';
export const USER_MYTASKSACTIVE_EDIT = '/user/mytasksactiveedit';

export const USER_MYTASKSASSIGNED = '/user/mytasksassigned';
export const USER_MYTASKSASSIGNED_EDIT = '/user/mytasksassignededit';

export const USER_MYTASKSBIDDED = '/user/mytasksbidded';
export const USER_MYTASKSBIDDED_EDIT = '/user/mytasksbiddededit';

export const USER_MYTASKSBOOKMARKED = '/user/mytasksbookmarked';
export const USER_MYTASKSBOOKMARKED_EDIT = '/user/mytasksbookmarkededit';

export const USER_MYTASKSCOMPLETED = '/user/mytaskscompleted';
export const USER_MYTASKSCOMPLETED_EDIT = '/user/mytaskscompletededit';

export const MOBILE_ACCOUNTS = '/m/accounts';
export const MOBILE_TASKS = '/m/tasks';

export const DISPUTE_RESOLUTION = '/dispute-resolve';
export const GET_HELP = '/gethelp';
export const FEEDBACK = '/feedback';
export const TERMSOFSERVICE = '/terms-of-service';
export const PRIVACY_POLICY = '/privacy-policy';


const prod = {
    url: {
        API_URL: 'https://australia-southeast1-giggonewzealand-51e35.cloudfunctions.net/handle_stripe_payments-stripe_payments',
        UPLOADFILE: 'https://australia-southeast1-giggonewzealand-51e35.cloudfunctions.net/handle_file_upload-file_upload',
        PAYMENTSUCCESS: 'https://giggo.co.nz/payment/setupsuccess',
        HTTP_REQ: "https://australia-southeast1-giggonewzealand-51e35.cloudfunctions.net/handle_http_request-http_requests",
        CONTACT_US: " https://handle-contact-us-contact-us-tm5pkmtqfa-ts.a.run.app",
        BASEURL: 'https://giggo.co.nz'
    }
}

const dev = {
    url: {
        // API_URL: 'https://australia-southeast1-giggonewzealand-51e35.cloudfunctions.net/handle_stripe_payments-stripe_payments',
        // UPLOADFILE: 'https://australia-southeast1-giggonewzealand-51e35.cloudfunctions.net/handle_file_upload-file_upload',
        // PAYMENTSUCCESS: 'https://giggonewzealand-51e35.web.app/payment/setupsuccess',
        // HTTP_REQ: "https://australia-southeast1-giggonewzealand-51e35.cloudfunctions.net/handle_http_request-http_requests",
        // BASEURL: 'https://giggonewzealand-51e35.web.app'
        API_URL: 'http://127.0.0.1:5001/giggonewzealand-51e35/australia-southeast1/handle_stripe_payments-stripe_payments',
        UPLOADFILE: 'http://127.0.0.1:5001/giggonewzealand-51e35/australia-southeast1/handle_file_upload-file_upload',
        PAYMENTSUCCESS: 'http://localhost:3000/payment/setupsuccess',
        HTTP_REQ: "http://127.0.0.1:5001/giggonewzealand-51e35/australia-southeast1/handle_http_request-http_requests",
        CONTACT_US: "http://127.0.0.1:5001/giggonewzealand-51e35/australia-southeast1/handle_contact_us-contact_us",
        BASEURL: 'http://localhost:3000'
    }
}


export const config = process.env.NODE_ENV === "development" ? dev : prod;