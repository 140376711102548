import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';

function BlogPost()
{
    const { slug } = useParams();
    const [Component, setComponent] = useState(null);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [error, setError] = useState(null);

    useEffect(() =>
    {
        // Dynamically import the blog component based on the slug
        import(`./Blogs/${slug}.js`)
            .then(module =>
            {
                const BlogComponent = module.default;
                setTitle(`Title for ${slug}`); // You can customize the title dynamically
                setDescription(`Description for ${slug}`); // Customize for SEO description
                setComponent(<BlogComponent />); // Set the dynamically loaded blog component
            })
            .catch(() =>
            {
                setError('Blog post not found');
            });
    }, [slug]);

    if (error)
    {
        return <h2>{error}</h2>;
    }

    return (
        <div>
            
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description} />
            </Helmet>
            {Component ? Component : <p>Loading...</p>}
        </div>
    );
}

export default BlogPost;
